import { Footer, FooterLinks, FooterLinksContainer, Header, BrandSearchInput } from '@okam/brand-ui/index'
import { Icon } from '@okam/shared-ui/index'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import tw from 'twin.macro'
import type { SiteOptionsQuery, MainMenuFragment, AllMembersFragment, Maybe } from 'gql/graphql'

type TSiteOptionsProps = Pick<SiteOptionsQuery, 'siteOptions'>['siteOptions']

export interface TLayoutHeader {
  menuItems?: MainMenuFragment
  variant?: 'white' | 'colored' | 'half'
  siteOptionsProps: TSiteOptionsProps
  languageSwitcherUri?: string
  allMembers?: Maybe<AllMembersFragment>
}

export const LayoutHeader = (props: TLayoutHeader) => {
  const { siteOptionsProps, menuItems, languageSwitcherUri, variant = 'white', allMembers } = props
  const siteProps = siteOptionsProps?.siteOptions
  const { t } = useTranslation('common')
  const contactTitle = t('FOOTER.CONTACT')
  const addressTitle = t('FOOTER.ADDRESS')
  const homeUri = t('ROUTES.HOME') ?? '/'
  const languageSwitcherUriDefault = t('LANGUAGESWITCHER.URI') ?? '/'
  const languageSwitcherLabel = t('LANGUAGESWITCHER.LOCALE') ?? ''
  const ariaMenuOpen = t('ARIA.MENU.OPEN') ?? ''
  const ariaMenuClose = t('ARIA.MENU.CLOSE') ?? ''
  const privacyPolicyTitle = t('FOOTER.PRIVACY_POLICY')
  const ariaMenuLogoLabel = t('ARIA.ARIA_HOMEPAGE') ?? ''
  const labelBackButton = t('BUTTON.BACK') ?? ''
  const searchLabel = t('ARIA.SEARCH_MEMBER') ?? ''
  const labelSocialMediaFooter = t('FOOTER.LABEL_SOCIAL_MEDIA') ?? ''
  const [openBurgerMenu, setOpenBurgerMenu] = useState(false)

  const membersData = allMembers?.nodes?.map((member) => ({
    id: member.id,
    title: member.title,
    url: member.link,
  }))

  const beforeMenu = (
    <BrandSearchInput
      id="menu-search-team"
      name="menu-search-team"
      placeholder={searchLabel}
      aria-label={searchLabel}
      dataFuzzySearch={membersData}
      tw="w-full bg-transparent md:ml-12 md:w-4/5 lg:w-full lg:ml-0 !text-white"
      icon={<Icon icon="Search" css={[tw`!text-white md:mr-10 lg:mr-0`]} />}
      handleResultPress={() => setOpenBurgerMenu(!openBurgerMenu)}
    />
  )
  const afterMenu = (
    <Footer
      tw="px-0 pb-0 pt-0 bg-transparent grid-cols-1 md:ml-12 lg:ml-0 md:pt-[9vh] md:grid-cols-4"
      contactTitle={contactTitle}
      contact={siteProps?.siteContactInfo ?? ''}
      addressTitle={addressTitle}
      address={siteProps?.siteAddressInfo ?? ''}
      privacyPolicyLink={siteProps?.sitePrivacyPolicy ?? ''}
      privacyPolicyTitle={privacyPolicyTitle}
      isFooterPanel
    >
      <FooterLinksContainer>
        <FooterLinks href={languageSwitcherUri ?? languageSwitcherUriDefault}>{languageSwitcherLabel}</FooterLinks>
        {siteProps?.siteLinkedin && (
          <FooterLinks target="_blank" rel="noopener noreferrer" href={siteProps?.siteLinkedin}>
            {labelSocialMediaFooter}
          </FooterLinks>
        )}
      </FooterLinksContainer>
    </Footer>
  )

  return (
    <Header
      openBurgerMenu={openBurgerMenu}
      setOpenBurgerMenu={setOpenBurgerMenu}
      homeLink={homeUri}
      alt={siteProps?.siteWhiteLogo?.altText ?? ''}
      variant={variant}
      openMenuAriaLabel={ariaMenuOpen}
      closeMenuAriaLabel={ariaMenuClose}
      logoPosition="left"
      linkLogoAriaLabel={ariaMenuLogoLabel}
      menuItems={menuItems}
      labelBackButton={labelBackButton}
      beforeMenu={beforeMenu}
      afterMenu={afterMenu}
      languageSwitcherAriaLabel={`${t('LANGUAGESWITCHER.ARIALABEL')}`}
      languageSwitcherUri={languageSwitcherUri ?? languageSwitcherUri}
      languageSwitcherLabel={languageSwitcherLabel}
      whiteLogo={siteProps?.siteWhiteLogo}
      coloredLogo={siteProps?.siteLogo}
      logoWidth={siteProps?.siteLogoWidth}
    />
  )
}
