import { useScrollDirection } from '@okam/core-lib/src/hooks/useScrollDirection'
import Hamburger from 'hamburger-react'
import Link from 'next/link'
import React, { useRef, useState } from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import tw from 'twin.macro'
import { useOnClickOutside } from 'usehooks-ts'
import Img from '@okam/brand-ui/src/components/Img/index'
import LanguageSwitcher from '../LanguageSwitcher'
import { getHeaderImageVariant } from './headerVariants'
import type { THeaderProps } from './interface'
import { MenuPanel } from './MenuPanel'
import { logoAlign } from './styles'

const Header = (props: THeaderProps) => {
  const {
    openBurgerMenu,
    setOpenBurgerMenu,
    homeLink,
    alt,
    variant = 'white',
    openMenuAriaLabel,
    closeMenuAriaLabel,
    logoPosition = 'left',
    linkLogoAriaLabel,
    menuItems,
    beforeMenu,
    afterMenu,
    labelBackButton,
    languageSwitcherAriaLabel,
    languageSwitcherUri,
    languageSwitcherLabel,
    whiteLogo,
    coloredLogo,
    logoWidth,
  } = props
  const [isSecondaryPanelOpen, setIsSecondaryPanelOpen] = useState(false)
  const ref = useRef(null)
  const { isHidden, isScrollTop } = useScrollDirection()
  const headerVariant = !isHidden && !isScrollTop ? 'colored' : variant
  const imageSrc = getHeaderImageVariant(
    headerVariant,
    whiteLogo?.sourceUrl ?? undefined,
    coloredLogo?.sourceUrl ?? undefined,
  )
  const imageAlt = headerVariant === 'white' ? whiteLogo?.altText : coloredLogo?.altText
  const logoPositionClasses = logoAlign[logoPosition]

  const handleClickOutside = () => {
    setOpenBurgerMenu(false)
    document.body.style.overflow = 'auto'
  }

  const handleToggle = () => {
    setOpenBurgerMenu(!openBurgerMenu)
    if (!openBurgerMenu) {
      setIsSecondaryPanelOpen(false)
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }

  useOnClickOutside(ref, handleClickOutside)

  return (
    <header
      tw="fixed top-0 z-50 h-[10vh] min-h-[4rem] max-h-24 w-screen"
      css={[
        tw`transition duration-500 ease-in-out`,
        isHidden ? tw`-translate-y-24 opacity-0` : tw`translate-y-0 opacity-100`,
        !isHidden && !isScrollTop && tw`bg-white`,
      ]}
    >
      <div css={[tw`grid grid-cols-12 items-center h-full`]}>
        <div css={[tw`col-span-9 pl-4 md:pl-12 md:col-span-10`, tw`${logoPositionClasses.logo}`]}>
          <Link href={homeLink} aria-label={linkLogoAriaLabel}>
            <Img src={imageSrc || ''} width={logoWidth ?? 150} height={150} alt={imageAlt || alt} />
          </Link>
        </div>
        <div css={[tw`col-span-1 self-center justify-self-end mr-8`, tw`${logoPositionClasses.languageSwitcher}`]}>
          <LanguageSwitcher
            variant={headerVariant}
            ariaLabel={languageSwitcherAriaLabel}
            label={languageSwitcherLabel}
            uri={languageSwitcherUri}
          />
        </div>
        <div css={[tw`col-span-2 md:col-span-1`, tw`${logoPositionClasses.burgerIcon}`]}>
          <div ref={ref}>
            <div
              tw="z-30 relative h-12 w-12 [border-radius: 25px]"
              css={[headerVariant === 'colored' ? tw`bg-tertiary-5` : tw`bg-tertiary-2 4xl:bg-tertiary-5`]}
            >
              <Hamburger
                size={16}
                toggle={handleToggle}
                toggled={openBurgerMenu}
                color={variant === 'colored' || (!isHidden && !isScrollTop) ? 'white' : '#10163b'}
                label={openBurgerMenu ? closeMenuAriaLabel : openMenuAriaLabel}
              />
            </div>
            <MenuPanel
              openBurgerMenu={openBurgerMenu}
              setOpenBurgerMenu={setOpenBurgerMenu}
              isSecondaryPanelOpen={isSecondaryPanelOpen}
              setIsSecondaryPanelOpen={setIsSecondaryPanelOpen}
              beforeMenu={beforeMenu}
              afterMenu={afterMenu}
              bgPattern="branches"
              menuItems={menuItems}
              labelBackButton={labelBackButton}
            />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
